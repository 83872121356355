import React, { useReducer, useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const defaultLanguage = require('./en.json');
function messages(lang) { return {...defaultLanguage, ...lang}; };

export const languageList = [
    {
        code: 'en',
        name: 'English',
        flag: require('svg-country-flags/svg/us.svg'),
        messages: defaultLanguage
    },
    {
        code: 'de',
        name: 'Deutsch',
        flag: require('svg-country-flags/svg/de.svg'),
        messages: messages(require('./de.json'))
    },
    {
        code: 'bs',
        name: 'Bosnian',
        flag: require('svg-country-flags/svg/ba.svg'),
        messages: messages(require('./bs.json'))
    },
    {
        code: 'es',
        name: 'Español',
        flag: require('svg-country-flags/svg/es.svg'),
        messages: messages(require('./es.json'))
    },
    {
        code: 'fr',
        name: 'Français',
        flag: require('svg-country-flags/svg/fr.svg'),
        messages: messages(require('./fr.json'))
    },
    {
        code: 'bg',
        name: 'Български',
        flag: require('svg-country-flags/svg/bg.svg'),
        messages: messages(require('./bg.json'))
    },
    {
        code: 'no',
        name: 'Norsk',
        flag: require('svg-country-flags/svg/no.svg'),
        messages: messages(require('./no.json'))
    },
    {
        code: 'ro',
        name: 'Român',
        flag: require('svg-country-flags/svg/ro.svg'),
        messages: messages(require('./ro.json'))
    }
];

const languages = languageList.reduce((a, b) => (a[b.code] = b) && a, {});
const LanguageContext = React.createContext();

export default function LanguageProvider(props) {
    const [ lang, setLang ] = useState(() => languages[localStorage.getItem('locale') || 'en'] || languages.en);

    return <LanguageContext.Provider value={{ lang, setLang }}>
        <IntlProvider messages={lang.messages} locale={lang.code} defaultLocale="en" {...props} />
    </LanguageContext.Provider>;
}

export function LanguageSwitcherBasic({ className }) {
    const { lang, setLang } = useContext(LanguageContext);
    const [ isOpen, toggleMenu ] = useReducer((a) => !a, false);

    const changeLang = (lang, e) => {
        e.preventDefault();
        setLang(lang);
        localStorage.setItem('locale', lang.code);
    };

    return <Dropdown isOpen={isOpen} toggle={toggleMenu} id="languagemenu" className={className}>
        <DropdownToggle tag="button" className="btn header-item waves-effect">
            { lang.flag && <img className="mr-2" src={lang.flag} alt="Header Language" height="16"/> } { lang.name } <span className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu right>
            { languageList
                .map(a => <DropdownItem tag="a" href="#" className="notify-item" onClick={changeLang.bind(this, a)}>
                     { a.flag && <img className="mr-2" src={a.flag} alt={a.name} height="8"/> } <span className="align-middle">{ a.name }</span>
                </DropdownItem>) }
        </DropdownMenu>
    </Dropdown>;
}

