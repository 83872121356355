import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';


import store from './store';
import LanguageProvider from './lang';
import Progress from './Progress';

const app = (
    <Progress>
        <Provider store={store}>
            <LanguageProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </LanguageProvider>
        </Provider>
    </Progress>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
