import React, { Component, useCallback, useState } from 'react';
import { Switch, BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import VerticalLayout from "./component/Layout/VerticalLayout";
import NonAuthLayout from "./component/NonAuthLayout/NonAuthLayout";

import Pageslogin from './pages/Authentication/Login';
import Logout from './pages/Authentication/Logout';
import Pagesregister from './pages/Authentication/Register';
import ForgetPassword from './pages/Authentication/ForgetPassword';

//Dashboard
import Dashboard from './pages/Dashboard/dashboard';
import Category from "./Category";
import Profile from "./pages/Profile";
import Certificate from './Certificate';

// Import scss
import "./theme.scss";
import 'react-toastify/dist/ReactToastify.css';
import { is_logged_in, set_logged_in_changed } from './api';
import Info from './module/Info';

export default function App() {
  const [wasLoggedIn] = useState(is_logged_in());

  const [isLoggedIn, setIsLoggedIn] = useState(is_logged_in());

  console.log('Is logged in:', isLoggedIn);

  set_logged_in_changed(useCallback(function() {
    if (isLoggedIn !== is_logged_in()) {
      setIsLoggedIn(is_logged_in());
    }
  }, [isLoggedIn]));
  
  if (!isLoggedIn) {
    return <NonAuthLayout>
      <Switch>
        <Route exact path="/login" component={Pageslogin} />
        <Route exact path="/register" component={Pagesregister} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Redirect to="/login" />
      </Switch>
    </NonAuthLayout>;
  }

  return <VerticalLayout>
    <Switch>
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/certificate" component={Certificate} />
      <Route exact path="/intro" component={Info} />
      <Route exact path="/category/:id" component={Category} />
      <Redirect exact path="/register" to="/profile" />
      <Redirect to="/dashboard" />
    </Switch>
  </VerticalLayout>;
}
