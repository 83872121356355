
import React, { useState, useContext, useEffect, useRef, useReducer, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, CustomInput, Form, Input } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import { tokenized, wrap } from './api';
import { getModuleName, SetBreadcrumb } from './Category';
import Validator from './component/Common/Validator';
import { TrackContext } from './Track';
import { MainContext, UserContext } from './UserProvider';

function CertificatePost({ onCertificate }) {
  const main = useContext(MainContext);
  const user = useContext(UserContext);
  const intl = useIntl();

  const menuId = intl.formatMessage({ id: 'MENU_ID' });

  const validator = useRef(new SimpleReactValidator({
    messages: {
        email: intl.formatMessage({ id: 'VALIDATION.EMAIL' }),
        default: intl.formatMessage({ id: 'VALIDATION.REQUIRED' })
    }
  }));

  const [ accepted, setAccepted ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ _, forceUpdate ] = useReducer(x => x + 1, 0);

  const generateCertificate = useCallback(async function(e) {
    e.preventDefault();

    if (!validator.current.allValid()) {
        validator.current.showMessages();
        forceUpdate();
        return ;
    }

    setLoading(true);
    try {
      var res = await tokenized.put('/certificate/' + menuId);
      onCertificate(res.data || false);
    } finally {
      setLoading(false); 
    }
  }, [onCertificate, menuId, loading]);

  const changeName = useCallback(async (e) => {
    e.preventDefault();

    await Swal.fire({
      title: intl.formatMessage({ id: 'CERTIFICATE.ENTER_NAME' }),
      input: 'text',
      inputValue: user.name,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: 'CERTIFICATE.CONFIRM' }),
      showLoaderOnConfirm: true,
      preConfirm: name => {
        return main
            .loading(async () => {
                var res = await tokenized.patch('/user/' + user.id, { ...user, name: name });
                await user.update();
            }, true);
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }, [user, intl]);

  if (loading)
    return null;

  return <Card>
    <CardHeader>
      <CardTitle>
        <b><FormattedMessage id="CERTIFICATE.TITLE" /></b>
      </CardTitle>
    </CardHeader>
    <CardBody>
      <Form onSubmit={generateCertificate}>
        <p>
          <FormattedMessage
            id="CERTIFICATE.ENSURE_PROFILE_CORRECT"
            values={{
              1: text => <Link to="/profile"><b>{text}</b></Link>
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="CERTIFICATE.NAME"
            values={{
              name: <b>{user.name}</b>,
              1: text => <a href="#" onClick={changeName}><i className="fa fa-pencil ml-1" /> {text}</a>
            }}
          />
        </p>
        <p>
          <Validator
              name="acceptence"
              value={accepted}
              type="accepted"
              validator={validator}>
              <CustomInput type="checkbox"
                  id="acceptence"
                  value={accepted}
                  disabled={loading}
                  label={<FormattedMessage id="CERTIFICATE.ACCEPTENCE" values={{ name: <b>{user.name}</b> }} />}
                  onChange={a => setAccepted(!accepted)}
              />
          </Validator>
        </p>
        <p>
          <Button type="submit" disabled={loading} color="primary"><i className="fa fa-save mr-1" /> <FormattedMessage id="CERTIFICATE.GENERATE" /></Button>
        </p>
      </Form>
    </CardBody>
  </Card>;
}

export default function Certificate() {
  const track = useContext(TrackContext);
  const user = useContext(UserContext);
  const intl = useIntl();
  const menuId = intl.formatMessage({ id: 'MENU_ID' });
  const { next_category } = track;

  const [ certificate, setCertificate ] = useState(null);

  useEffect(function() {
    async function update() {
      const cert = await tokenized.get('/certificate/' + menuId);
      setCertificate(cert.data || false);
    }

    update();
  }, [menuId]);
  
  if (certificate === null)
    //return <Loading />;
    return null;

  if (certificate === false) {
    return <>
      <SetBreadcrumb title="Certificate" items={[]} />
      { !!next_category && <Alert color="danger"><i className="fa fa-times-circle" />{' '}<FormattedMessage id={ user.type === 512 ? "MODULE.PREVIOUS_TASK_NEEDED_ADMIN" : "MODULE.PREVIOUS_TASK_NEEDED"} values={{ task: <b><Link to={"/category/" + next_category.id}>{ getModuleName(next_category) }</Link></b> }} /></Alert> }
      { !!next_category && <Alert color="success"><i className="fa fa-check" />{' '}<FormattedMessage id="CERTIFICATE.HEADLINE" values={{ 1: text => <b>{text}</b> }} /></Alert> }
      { (!next_category || user.type === 512) && <CertificatePost onCertificate={setCertificate} /> }
    
    </>;
  }

  return <>
    <SetBreadcrumb title="Certificate" items={[]} />
    <Alert color="success">
      <p>
        <i className="fa fa-check" />{' '}
        <FormattedMessage id="CERTIFICATE.GENERATED_AT" values={{ date: <b>{new Date(certificate.generated_at).toString()}</b> }} />
      </p>
      <p>
        <FormattedMessage id="CERTIFICATE.DOWNLOAD_MESSAGE" values={{ 1: text => <a href={ wrap(certificate.certificate.access_url) } target="_blank">{text}</a> }} />
      </p>
      <p className="pb-0 mb-0">
        <a className="btn btn-outline-primary" href={ wrap(certificate.certificate.access_url) } target="_blank">
        <i className="fa fa-download" />{' '}<FormattedMessage id="CERTIFICATE.DOWNLOAD" /></a>
      </p>
    </Alert>
    <Alert color="warning">
      <i className="fa fa-exclamation-triangle" />{' '}
      <FormattedMessage id="CERTIFICATE.SUPPORT" values={{ email: <b><FormattedMessage id="SUPPORT.EMAIL" /></b> }} />
    </Alert>
  </>;
}
